<template>
<card-template
  :item="item"
  :selected="selected"
  :compact="compact"
  class="card-call"
  :class="{
    'card-call--compact': !!compact,
  }"
>
  <template #label="{ label }">
    {{ label }}
    <notification
      v-if="compact && item.data.thread && item.data.thread.length"
      :count="item.data.thread.length"
    />
  </template>
  <template #content>
    <div class="property property--subject">
      {{ item.contacted_person }}
      <!-- <notification
        v-if="item.data.thread && item.data.thread.length"
        :count="item.data.thread.length"
      /> -->
    </div>
    <div class="property property--from">
      Phone Number: {{item.phone_no}}
    </div>
    <!-- <div class="property property--to">
      to {{ item.data.to.join(', ') }}
    </div> -->
    
    <div class="property property--description"
      v-html="item.content"
    ></div>
  </template>
  <template #content-compact>
    <div class="property property--subject">
      {{ item.contacted_person }}
    </div>
    <div class="property property--date">
      {{ formatDateTime(item?.event_timekey) }}
    </div>
  </template>
</card-template>
</template>

<script>
import cardTemplate from "./card-base-template.vue";
import dateTimeFormatMixin from "@shared/mixins/dateTimeFormat";
const notification = () => import("@/components/dashboard-components/checklist/components/notification.vue");

export default {
  name: 'card-call',
  components: {
    cardTemplate,
    notification,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      required: false,
    },
    compact: {
      type: Boolean,
      required: false,
    },
  },
  mixins: [dateTimeFormatMixin],
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
</style>