import {
    formatDateTimeWithTimeZone,
    formatDateWithTimeZone,
    formatTimeWithTimeZone,
} from "./helpers";

export default {
    methods: {
        formatDateTime(date) {
            return formatDateTimeWithTimeZone(date);
        },
        formatDate(date) {
            return formatDateWithTimeZone(date);
        },
        formatTime(date) {
            return formatTimeWithTimeZone(date);
        },
    },
};
