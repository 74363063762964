const thresholdName = "year";

const formatter = new Intl.DateTimeFormat("en-US", {
    dateStyle: "medium",
    timeStyle: "short",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
});

const buildUTCDate = (date) => {
    if (!date) return "";

    const initialDate = new Date(date);

    return new Date(Date.UTC(
        initialDate.getFullYear(),
        initialDate.getMonth(),
        initialDate.getDate(),
        initialDate.getHours(),
        initialDate.getMinutes(),
        initialDate.getSeconds()
    )).toUTCString();
};

const getDateParts = (date) => formatter.formatToParts(new Date(buildUTCDate(date)));
const convertPartsToString = (parts = []) => parts.map(p => p.value).join("").trim();

export const formatDateTimeWithTimeZone = (date) => {
    if (!date) return "";

    return formatter.format(new Date(buildUTCDate(date)));
};

export const formatDateWithTimeZone = (date) => {
    if (!date) return "";

    const parts = getDateParts(date);
    const thresholdIndex = parts.findIndex(p => p.type === thresholdName) + 1;

    return convertPartsToString(parts.slice(0, thresholdIndex));
};

export const formatTimeWithTimeZone = (date) => {
    if (!date) return "";

    const parts = getDateParts(date);
    const thresholdIndex = parts.findIndex(p => p.type === thresholdName) + 2;

    return convertPartsToString(parts.slice(thresholdIndex, parts.length));
};